(async function() {

  // Clear up data
  // sessionStorage.setItem('opData', '[]');
  sessionStorage.setItem("opFilters", "{}");

  let min = 3;
  let max = 28;

  const filters = {
    ano: [],
    batalhao: [],
    localidade: [],
    mortos: [],
    regiao: []
  };

  function clearFilters() {
    filters.ano = [];
    filters.batalhao = [];
    filters.localidade = [];
    filters.mortos = [];
    filters.regiao = [];
  }

  function getFilters() {
    return {
      ano: [...new Set(filters.ano)].sort(),
      batalhao: [...new Set(filters.batalhao)].sort(),
      localidade: [...new Set(filters.localidade)].sort(),
      mortos: [min, max],
      regiao: [...new Set(filters.regiao)].sort()
    };
  }

  function setFilters() {
    const form = document.querySelector(".infografico .filtros > form");
    const filters = getFilters();

    form.innerHTML = "";
    setRegiao(form, filters);
    setLocalidade(form, filters);
    setMortos(form, filters);
    setAno(form, filters);
    setBatalhao(form, filters);
    setOperacao(form);
  }

  function setListeners(el) {
    el.addEventListener("change", updateSelectedFilters);
  }

  function setRegiao(form, filters) {
    const state = getState();
    const fieldset = document.createElement("fieldset");
    const title = document.createElement("h5");
    const div = document.createElement("div");
    const border = document.createElement("div");
    title.innerText = "Região";
    border.classList = "border";
    div.classList = "scrollbox";

    filters.regiao.forEach(function(filter) {
      const label = document.createElement("label");
      const input = document.createElement("input");
      input.type = "checkbox";
      input.value = filter;
      input.classList = "regiao";
      label.innerText = filter;
      label.classList = "item";
      label.appendChild(input);
      div.appendChild(label);

      if (state.regiao && state.regiao.indexOf(filter) > -1) {
        input.setAttribute("checked", "checked");
      }
      setListeners(input);
    });

    fieldset.appendChild(title);
    fieldset.appendChild(div);
    form.appendChild(fieldset);
    form.appendChild(border);
  }

  function setLocalidade(form, filters) {
    const state = getState();
    const fieldset = document.createElement("fieldset");
    const title = document.createElement("h5");
    const div = document.createElement("div");
    const border = document.createElement("div");
    title.innerText = "Localidade";
    border.classList = "border";
    div.classList = "scrollbox";

    filters.localidade.forEach(function(filter) {
      const label = document.createElement("label");
      const input = document.createElement("input");
      input.type = "checkbox";
      input.value = filter;
      input.classList = "localidade";
      label.innerText = filter;
      label.classList = "item";
      label.appendChild(input);
      div.appendChild(label);

      if (state.localidade && state.localidade.indexOf(filter) > -1) {
        input.setAttribute("checked", "checked");
      }
      setListeners(input);
    });

    fieldset.appendChild(title);
    fieldset.appendChild(div);
    form.appendChild(fieldset);
    form.appendChild(border);
  }

  function setMortos(form, filters) {
    const state = getState();
    const fieldset = document.createElement("fieldset");
    const title = document.createElement("h5");
    const div = document.createElement("div");
    const border = document.createElement("div");
    const legend1 = document.createElement("div");
    title.innerText = "Mortos";
    border.classList = "border";
    div.classList = "scrollbox";
    legend1.classList = "legend";
    const legMin = document.createElement("span");
    legMin.innerText = min;
    const legCur = document.createElement("span");
    legCur.classList = "current";
    const legMax = document.createElement("span");
    legMax.innerText = max;
    legend1.appendChild(legMin);
    legend1.appendChild(legCur);
    legend1.appendChild(legMax);
    const legend2 = legend1.cloneNode(true);
    legend1.classList.add("min");
    legend2.classList.add("max");

    const minInput = document.createElement("input");
    minInput.type = "range";
    minInput.classList = "min";
    minInput.id = "minInput";
    minInput.min = min;
    minInput.max = max;

    minInput.value = min;

    if (state.min) {
      minInput.value = state.min[0];
      legend1.firstChild.nextSibling.innerText = state.min[0];
    }
    setListeners(minInput);

    const maxInput = document.createElement("input");
    maxInput.type = "range";
    maxInput.classList = "max";
    maxInput.id = "maxInput";
    maxInput.min = min;
    maxInput.max = max;
    maxInput.value = max;

    if (state.max) {
      maxInput.value = state.max[0];
      legend2.firstChild.nextSibling.innerText = state.max[0];
    }
    setListeners(maxInput);

    const minLabel = document.createElement("label");
    minLabel.innerText = "Mínimo";
    minLabel.classList = "rangelabel";
    minLabel.setAttribute("for", "minInput");
    div.appendChild(minLabel);
    div.appendChild(minInput);
    div.appendChild(legend1);

    const maxLabel = document.createElement("label");
    maxLabel.innerText = "Máximo";
    maxLabel.classList = "rangelabel";
    maxLabel.setAttribute("for", "maxInput");
    div.appendChild(maxLabel);
    div.appendChild(maxInput);
    div.appendChild(legend2);

    fieldset.appendChild(title);
    fieldset.appendChild(div);
    form.appendChild(fieldset);
    form.appendChild(border);
  }

  function setBatalhao(form, filters) {
    const state = getState();
    const fieldset = document.createElement("fieldset");
    const title = document.createElement("h5");
    const div = document.createElement("div");
    const border = document.createElement("div");
    title.innerText = "Batalhão";
    border.classList = "border";
    div.classList = "scrollbox";

    filters.batalhao.forEach(function(filter) {
      const label = document.createElement("label");
      const input = document.createElement("input");
      input.type = "checkbox";
      input.value = filter;
      input.classList = "batalhao";
      label.innerText = filter;
      label.classList = "item";
      label.appendChild(input);
      div.appendChild(label);

      if (state.batalhao && state.batalhao.indexOf(filter) > -1) {
        input.setAttribute("checked", "checked");
      }
      setListeners(input);
    });

    fieldset.appendChild(title);
    fieldset.appendChild(div);
    form.appendChild(fieldset);
    form.appendChild(border);
  }

  function setOperacao(form) {
    const state = getState();
    const fieldset = document.createElement("fieldset");
    const title = document.createElement("h5");
    const wrapper = document.createElement("div");
    const div = document.createElement("div");
    const inner = document.createElement("div");
    const swtch = document.createElement("div");
    const label = document.createElement("label");
    const input = document.createElement("input");
    const btnWrap = document.createElement("div");
    const button = document.createElement("a");

    wrapper.classList = 'wrap';
    btnWrap.classList = 'button';
    button.setAttribute('href', 'https://docs.google.com/spreadsheets/d/1AyG1-tD1H9fnL-sJ9QNTcLTEh-4wI8Q-/export?gid=102886874&format=xlsx');
    button.setAttribute('target', '_blank');
    button.classList = 'download';
    button.innerText = 'Download dos dados completos';

    title.innerHTML = "Operação<br/>Vingança";
    div.classList = "flipswitch";
    inner.classList = "flipswitch-inner";
    swtch.classList = "flipswitch-switch";

    input.id = "fs";
    input.type = "checkbox";
    input.value = 1;
    input.classList = "operacao";

    label.classList = "flipswitch-label";
    label.setAttribute("for", "fs");
    label.appendChild(inner);
    label.appendChild(swtch);

    div.appendChild(input);
    div.appendChild(label);

    if (state.operacao && state.operacao[0] === "1") {
      input.setAttribute("checked", "checked");
    }

    btnWrap.appendChild(button);
    wrapper.appendChild(div);
    wrapper.appendChild(btnWrap);
    fieldset.appendChild(title);
    fieldset.appendChild(wrapper);
    form.appendChild(fieldset);

    setListeners(input);
  }

  function setAno(form, filters) {
    const state = getState();
    const fieldset = document.createElement("fieldset");
    const title = document.createElement("h5");
    const div = document.createElement("div");
    const border = document.createElement("div");
    title.innerText = "Ano";
    border.classList = "border";
    div.classList = "scrollbox";

    filters.ano.forEach(function(filter) {
      const label = document.createElement("label");
      const input = document.createElement("input");
      input.type = "checkbox";
      input.value = filter;
      input.classList = "ano";
      label.innerText = filter;
      label.classList = "item";
      label.appendChild(input);
      div.appendChild(label);

      if (state.ano && state.ano.indexOf(filter.toString()) > -1) {
        input.setAttribute("checked", "checked");
      }

      setListeners(input);
    });

    fieldset.appendChild(title);
    fieldset.appendChild(div);
    form.appendChild(fieldset);
    form.appendChild(border);
  }

  function csvJSON(text, quoteChar = "\"", delimiter = ",") {
    var rows = text.split("\n");
    var headers = rows[0].trim().split(",");

    const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, "gs");

    const match = line => [...line.matchAll(regex)]
      .map(m => m[2])
      .slice(0, -1);

    var lines = text.split("\n");
    const heads = headers ?? match(lines.shift());
    lines = lines.slice(1);

    return lines.map(line => {
      return match(line).reduce((acc, cur, i) => {
        // replace blank matches with `null`
        const val = cur.length <= 0 ? null : Number(cur) || cur;
        const key = heads[i] ?? `{i}`;
        return { ...acc, [key]: val };
      }, {});
    });
  }

  const hideLoader = () => {
    const loaders = document.querySelectorAll(".loader");

    if (loaders) {
      loaders.forEach((el) => el.style.display = "none");
    }

    const hiddenContent = document.querySelector(".content.hide");

    if (hiddenContent) {
      hiddenContent.classList.remove("hide");
    }
  };

  const getData = async () => {
    const cached = sessionStorage.getItem("opData");

    if (cached && cached.length > 0) {
      //return JSON.parse(cached);
    }

    const result = await fetch(`https://docs.google.com/spreadsheets/d/e/2PACX-1vRMyozn63yqwIps1W4tpmXmgb4PSaLCMyztt2VyEPMGdvuYD9Canlw8KdUHus1X-TY-baTFjKrjLCIO/pub?gid=365510275&single=true&output=csv`);
    const data = await result.text();

    hideLoader();
    return csvJSON(data);
  };

  const getTooltip = (obj) => {
    let style = "";
    let link = '';

    if (obj.link) {
      link = `<span class="line link">
                <a href="${obj.link}" target="_blank">Ler mais</a>
              </span>`;
    }

    const span = `<span class="tooltip-content" style="${style}">
                  <h5 class="title">${obj.localidade}</h5>
                  <span class="line">
                    <span class="name">Região:&nbsp;</span>${obj.regiao}
                  </span>
                  <span class="line">
                    <span class="name">Localidade:&nbsp;</span>${obj.localidade}
                  </span>
                  <span class="line">
                    <span class="name">Número de mortos:&nbsp;</span>${obj.mortos}
                  </span>
                  <span class="line">
                    <span class="name">Data:&nbsp;</span>${obj.dia}/${obj.mes}/${obj.ano}
                  </span>
                  <span class="line">
                    <span class="name">Batalhão:&nbsp;</span>${obj.unidades_policiais}
                  </span>
                  <span class="line">
                    <span class="name">Operação Vingança:&nbsp;</span>${obj.operacao ? "Sim" : "Não"}
                  </span>
                  ${link}
                </span>`;

    return span;
  };


  const prepareNewData = (data) => {
    clearFilters();
    document.getElementById("chart").style.opacity = 0.3;
    let datasets = [];
    let unidades = {};

    for (let i = 0; i < data.length; i++) {
      let unidade = datasets.find(unidade => unidade.label === data[i].unidades_policiais) || {};
      let index = datasets.indexOf(unidade);

      unidade.label = data[i].bairro || "não identificado";
      let unit = unidades[unidade.label] || {};
      unit.count = unit.count || 0;
      unit.num = unit.num || 0;
      unit.num += 1;
      unit.count += data[i].mortos;
      unidade.data = unidade.data || [];

      if (isNaN(unit.count)) {
        continue;
      }

      const obj = {
        x: new Date(data[i].ano, data[i].mes, data[i].dia),
        z: data[i].mortos,
        y: unit.count,
        tooltip: getTooltip(data[i])
      };

      if ( data[i].link ) {
        obj.click = function(e) {
          window.location = data[i].link;
        }
      }

      const regex = new RegExp(/,|\se\s/);
      const batalhoes = data[i].unidades_policiais.split(regex).map((unidade) => unidade.trim());

      batalhoes.forEach((u) => {
        filters.batalhao.push(u);
      });

      unidade.data.push(obj);
      filters.ano.push(data[i].ano);
      filters.regiao.push(data[i].regiao);
      filters.localidade.push(data[i].localidade);

      unidades[unidade.label] = unit;

      if (index > -1) {
        datasets[index] = obj;
      } else {
        datasets.push(obj);
      }
    }

    setFilters();
    document.getElementById("chart").style.opacity = 1;
    return datasets;
  };

  const data = await getData();
  window.ops = data;
  sessionStorage.setItem("opData", JSON.stringify(data));

  async function updateSelectedFilters(el) {
    document.getElementById("chart").style.opacity = 0.3;
    const state = JSON.parse(sessionStorage.getItem("opFilters")) || {};
    const cls = el.target.classList;
    const list = state[el.target.classList] || [];
    const index = list.indexOf(el.target.value);

    if (cls[0] === "max" || cls[0] === "min") {
      list[0] = el.target.value;
    } else {
      if (index > -1) {
        list.splice(index, 1);

      } else {
        list.push(el.target.value);
      }
    }

    if (list.length < 1) {
      delete state[el.target.classList];
    } else {
      state[el.target.classList] = list;
    }

    sessionStorage.setItem("opFilters", JSON.stringify(state));

    updateChart(state, await getData());
    document.getElementById("chart").style.opacity = 1;
  }

  function updateDataset(data) {
      newChart.options.data[0].dataPoints = prepareNewData(data);
      newChart.render();
  }

  function updateChart(state, data) {
    const stateSize = Object.keys(state).length;

    if (stateSize === 0) {
      updateDataset(data)
    } else {
      const newData = data.filter((ocorrencia) => {

        if (state.ano) {
          if (state.ano.indexOf(ocorrencia.ano.toString()) === -1) {
            return false;
          }
        }

        if (state.regiao) {
          if (state.regiao.indexOf(ocorrencia.regiao) === -1) {
            return false;
          }
        }

        if (state.localidade) {
          if (state.localidade.indexOf(ocorrencia.localidade) === -1) {
            return false;
          }
        }

        if (!state.min || state.min.length < 1) {
          state.min = [min];
        }

        if (!state.max || state.max.length < 1) {
          state.max = [max];
        }

        if (state.min[0] > ocorrencia.mortos || state.max[0] < ocorrencia.mortos) {
          return false;
        }


        if (state.batalhao) {
          const batalhoes = ocorrencia.unidades_policiais;
          let pass = false;
          for (var i = 0; i < state.batalhao.length; i++) {
            if (batalhoes.indexOf(state.batalhao[i]) > -1) {
              pass = true;
            }
          }

          if (!pass) {
            return false;
          }
        }

        if (state.operacao && state.operacao[0] === "1") {
          if (ocorrencia.operacao !== "SIM") {
            return false;
          }
        }

        return true;
      });
      updateDataset(newData)
    }
  }

  function getState() {
    return JSON.parse(sessionStorage.getItem("opFilters")) || {};
  }

  const clear = document.querySelector(".filtros .clear");
  clear.addEventListener("click", async (e) => {
    e.preventDefault();
    clearFilters();
    sessionStorage.setItem("opFilters", "{}");
    updateDataset(await getData());
  });

  const open = document.querySelector(".mobtitle a");
  open.addEventListener("click", (e) => {
    e.preventDefault();
    document.querySelector('.infografico .filtros').classList.toggle('open');
  });

  CanvasJS.addCultureInfo('pt', {
    decimalSeparator: ',',
    digitGroupSeparator: '.',
    days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  })

  const newChart = new CanvasJS.Chart("chart", {
    animationEnabled: true,
    theme: 'light2',
    culture: 'pt',
    toolTip: {
      contentFormatter: e => e.entries[0].dataPoint.tooltip
    },
    axisX: {
      labelFormatter: e => CanvasJS.formatDate( e.value, "MMMM \d\e YYYY", 'pt'),
      minimum: new Date(2016,07,01)
    },
    data: [
      {
        type: "bubble",
        dataPoints: prepareNewData(await getData())
      }
    ]
  });

  newChart.render();
})();



